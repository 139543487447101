import {ReduceStore} from 'flux/utils';
import FiltersActions from './FiltersActions';
import Dispatcher from '../Dispatcher';
import api from '../../lib/api';
import debug from '../../lib/debug';
import AsyncStatusHelpers, {AsyncStatusTypes} from './utils/AsyncStatusHelpers';
import UserActions from "../UserActions";
import LocationStore from "../LocationStore";
import LocationActions from "../LocationActions";

const defaultFilters = {
    year: {
        label: 'Year',
        key: 'year',
        items: [],
        displayType: "select",
        multiplicity: "single",
        isServerFilter: true,
        value: undefined,
        spacing: 2
    },
    granularity: {
        label: "Granularity",
        key: "granularity",
        displayType: "select",
        multiplicity: "single",
        items: [
            // {
            //     "label": "Year to Date",
            //     "value": "ytd"
            // },
            {
                "label": "Quarter",
                "value": "quarters"
            },
            {
                "label": "Month",
                "value": "months"
            },
            {
                "label": "Months & Quarters",
                "value": "monthsQuarters"
            }
        ],
        isServerFilter: false,
        value: "quarters",
        spacing: 2
    },
    implementations: {
        label: "Module",
        key: "implementations",
        displayType: "select",
        multiplicity: "multiple",
        items: [],
        isServerFilter: false,
        value: [],
        spacing: 2
    },
    physicians: {
        label: "Provider",
        key: "physicians",
        displayType: "select",
        multiplicity: "multiple",
        items: [],
        isServerFilter: true,
        value: [],
        spacing: 3
    },
    participants: {
        label: "Site",
        key: "participants",
        displayType: "select",
        multiplicity: "multiple",
        items: [],
        isServerFilter: true,
        value: [],
        spacing: 3
    }
};

class FiltersStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            data: defaultFilters,
            status: "init" // "loading", "loaded", "error", "init"
        };
    }

    reduce(state, action) {
        let match;
        switch (action.type) {
            case UserActions.LOADED:
            case LocationActions.LOCATION_CHANGED: {
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["DASHBOARD"].includes(LocationStore.getRouteKey())) {
                    return state; // we only need this data for the above views.
                }
                match = LocationStore.getMatch();
                if (match && match.params) {
                    const year = match.params.year;
                    const orgKey = match.params.orgKey;
                    const implKey = match.params.implKey;
                    state = {...state, status: AsyncStatusTypes.loading};
                    api.loadDashboardFilters(year, orgKey, implKey).then((res) => {
                        Dispatcher.dispatch({
                            type: FiltersActions.load,
                            data: res.data
                        });
                        return Promise.resolve(res);
                    }).catch((error) => {
                        Dispatcher.dispatch({
                            type: FiltersActions.error
                        });
                        return Promise.reject(error);
                    });
                }
                return state;
            }
            case FiltersActions.load: {
                const data = {...state.data};
                action.data.forEach((it) => {
                    const defaultConfig = data[it.key] || {};
                    const displayType = defaultConfig.displayType;
                    const multiplicity = it.multiplicity;
                    data[it.key] = {
                        ...defaultConfig,
                        ...it,
                        displayType: displayType,
                        multiplicity,
                        value: multiplicity === "multiple" ? [] : (it.items[0] || {}).value
                    };
                });
                state = {
                    data: data,
                    status: AsyncStatusTypes.loaded
                };
                return state;
            }
            case FiltersActions.error: {
                state = {...state, status: AsyncStatusTypes.error};
                return state;
            }
            case FiltersActions.change: {
                state = {
                    ...state,
                    data: {
                        ...state.data,
                        [action.filterKey]: {
                            ...state.data[action.filterKey],
                            value: action.value
                        }
                    }
                };
                return state;
            }
            default:
                return state;
        }
    }

    isLoaded() {
        return AsyncStatusHelpers.isLoaded(this.getState());
    }

    isError() {
        return AsyncStatusHelpers.isError(this.getState());
    }

    getAllFilters() {
        const filtersMap = this.getState().data;
        return Object.keys(filtersMap).map(key => filtersMap[key]);
    }

    getAllFiltersValue(serverOnly = false) {
        const filtersMap = this.getState().data;
        const values = {};
        Object.keys(filtersMap).forEach(key => {
            if(serverOnly && !filtersMap[key].isServerFilter) return;
            values[key] = filtersMap[key].value;
        });
        return values;
    }

    isServerFilter(key) {
        return this.getFilter(key).isServerFilter === true;
    }
    getFilter(key) {
        const filtersMap = this.getState().data;

        if(!filtersMap.hasOwnProperty(key)) {
            debug.log(`FiltersStore.getFilter(): unknown filter key "${key}"`, Object.keys(filtersMap));
            return {};
        }
        return filtersMap[key];
    }
    getFilterValue(key) {
        const filter = this.getFilter(key);
        return filter.value;
    }
    getFilterItemInfoByValue(key, value) {
        const filter = this.getFilter(key);
        if(!Array.isArray(filter.items)) {
            debug.log(`FiltersStore.getFilterItemInfoByValue() filter "${key}" has no options.`);
            return false;
        }
        const found = filter.items.find(it => (it.value || "empty").toString() === (value || "").toString());
        if(!found) {
            debug.log(`FiltersStore.getFilterItemInfoByValue() filter "${key}" has no option with the value "${value}".`);
            return false;
        }
        return found;
    }
}

export default new FiltersStore();
